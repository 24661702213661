<template>
  <div class="feed container" style="background: #ffffff">
    <link
      href="https://fonts.googleapis.com/css2?family=Amiri:ital@1&display=swap"
      rel="stylesheet"
    />
    <div class="heros" style="margin-bottom: 2px;">
      <h4 class="title is-4 is-">
        Invites generation
        <!-- <b-button
          class="is-pulled-right"
          size="is-small"
          type="is-default"
          @click="alertThis"
        >Generate 50 invitations</b-button>-->
        <b-input
        type="number"
          style="
            display: inline-block;
            padding: 0 px;
            zoom: 0.8;
            vertical-align: bottom;
            margin-right: 15px;
          "
          v-model="invitesCount"
        ></b-input>
        <b-button
          style="line-height: 15px; height: 30px"
          size="is-small"
          type="is-success"
          @click="updateInvitations()"
          >Update</b-button
        >
        <!-- <b-slider
          v-model="invitesCount"
          style="margin-bottom: 20px; display: inline-block;"
          lazy
          @change="updateInvitations()"
        ></b-slider> -->
        <b-button
          class="is-pulled-right"
          style="line-height: 15px; height: 30px"
          size="is-small"
          @click="toggleBackside()"
          >Flip</b-button
        >
      </h4>
    </div>
    <section class="section" style="padding-top: 0px; padding-bottom: 0px;">
      <!-- <b-field>
        <b-input v-model="invitesCount" type="number"></b-input>
      </b-field> -->
      <div class="is-clearfix">
        <div class="columns3 is-multiline" style="margin-left: 7%; margin-right: 7%;">
          <div
            v-for="item in newInvites"
            :key="item.id"
            class="column inviteBlock is-quarter is-paddingless"
            style="width: 50%"
            :class="{ hidden: backSide }"
          >
            <div class="columnInlineBlock" v-if="!backSide">
              <div
                class="halfHeightBlock"
                style="border-bottom: 1px solid #f2f2f2"
              ></div>
              <div class="halfHeightBlock">
                <div
                  style="
                    letter-spacing: 2px;
                    font-size: 14px;
                    padding-top: 15px;
                    font-weight: 500;
                  "
                >
                  Free Invitation
                </div>
                <img
                  src="@/assets/logo_old.png"
                  style="max-height: 100px; padding-top: 5px"
                  class="logo"
                  alt
                />
                <i class="fas fa-recycle" style="font-size: 12px"></i>
                <div class="bottom is-clearfix" style="font-size: 10px">
                  Not for resale.
                </div>
              </div>
            </div>
            <div v-if="backSide" class="backsidef columnInlineBlock">
              <div class="halfHeightBlock">
                <span class="qrlabel">
                  Scan QR code
                  <i class="fas fa-mobile-alt"></i>
                </span>
                <qrcode-vue
                  :value="item.code"
                  :size="110"
                  level="M"
                  class
                  style="padding-top: 20px"
                />
              </div>
              <div
                class="halfHeightBlock aboutText"
                style="transform: rotate(180deg);font-weight: 500;"
              >
                This invitation, is giving you lifetime access to<br>
                <strong>London Elegance Club</strong>. <br /><br />
                Scan the code below & join us<br /> in an exciting journey full
                of <br />feel-good experiences that will improve<br> your health &
                well-being!
              </div>
            </div>
            <div class="is-clearfix"></div>
          </div>
        </div>
      </div>
      <!-- <div style="clear: both;"></div> -->
    </section>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { uuid } from 'vue-uuid'

export default {
  name: 'AdminInvitesGenerate',
  components: {
    // MembersTemplate
    QrcodeVue,
    // UUID
  },
  data() {
    return {
      backSide: false,
      invitesCount: 10,
      newInvites: [],
    }
  },
  methods: {
    toggleBackside() {
      this.backSide = !this.backSide
    },
    updateInvitations() {
      const mainUrl = 'https://londoneleganceclub.com/code'
      let newInvites = []
      for (var i = 0; i < this.invitesCount; i++) {
        let newObj = {
          id: i + 1,
          code: `${mainUrl}/${uuid.v4()}/${this.randomString()}`,
        }
        newInvites.push(newObj)
        this.newInvites = newInvites
        this.$notify({ type: 'success', message: 'Pow! updated!' })
      }
    },
    randomString() {
      return (
        Math.random().toString(36).substring(2, 8) +
        Math.random().toString(36).substring(2, 8)
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.columnInlineBlock {
  display: inline-block;
  width: 100%;
}
.inviteBlock {
  border: 1px dashed #acacac;
  border-bottom: 0px;
  border-left: 0px;
  position: relative;
  /* font-family: 'Amiri', serif; */
  /* font-weight: bold; */
  font-weight: 300;
  .backside {
    display: none;
  }
  &.hidden {
    padding: 1px;
    border: none !important;
    .top,
    .bottom,
    .logo,
    .qrcode,
    .qrlabel,
    .fa-recycle {
      opacity: 0;
    }
    .backside {
      display: block;
      position: absolute;
      top: 120px;
      font-weight: 200;
      font-size: 16px;
      text-align: center;
      padding: 10px;
      width: 100%;
    }
  }
  &:nth-child(odd) {
    border-left: 1px dashed #acacac;
  }
  .top,
  .bottom {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 16px;
  }
  .top {
    margin-bottom: 10px;
    padding: 0 15px;
    padding-top: 5px;
  }
  .qrlabel {
    font-size: 9px;
    font-weight: 200;
    display: block;
    /* margin-top: -2px; */
    .fa-mobile-alt {
      padding-left: 3px;
    }
  }
  .logo {
    /* margin: 14px;
    margin-top: 15px;
    margin-left: 45px; */
  }
  .is-quarter {
    width: 25%;
  }
  .qrcode {
    margin: 24px;
    margin-top: 0px;
    margin-right: 45px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .fa-recycle {
    // margin-left: 20px;
    position: absolute;
    bottom: 5px;
    left: 7px;
  }
  .inlineBlock {
    display: inline-block;
  }
}
</style>
<style lang="scss">
.vue-back-to-top {
  display: none !important;
}
/* @media print { */
div.columns {
  overflow: visible;
}
div.inviteBlock {
  page-break-inside: avoid;
  break-inside: avoid !important;
  display: block;
  float: left;
}
div.halfHeightBlock {
  height: 170px;
  position: relative;
}
div.aboutText {
  font-size: 11px;
  padding: 5px 12px;
  padding-top: 30px;
}
/* } */
</style>

// -8,250.00	
// -3,444.58	
// -749.71


// 21720
// 12444.29