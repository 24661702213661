<template>
  <div class="feed container" style="background: #ffffff">
    <div class="hero">
      <h4 v-if="!$route.params.id" class="title is-4">New Post</h4>
      <h4 v-if="$route.params.id" class="title is-4">Edit Post</h4>
    </div>
    <section style="max-width: 600px; margin: auto">
      <b-field label="Title">
        <b-input v-model="post.title"></b-input>
      </b-field>
      <b-field label="Category" class="catClass">
        <b-select
          v-model="post.category"
          placeholder="Select a category"
          expanded
          @input="updateSubcategories"
        >
          <option
            v-for="option in categories"
            :key="option.id"
            :value="option.id"
          >
            {{ option.value }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Sub-Category" class="catClass">
        <b-select
          v-model="post.subcategory"
          placeholder="Select a subcategory"
          expanded
        >
          <option
            v-for="option in subcategories"
            :key="option.id"
            :value="option.id"
          >
            {{ option.value }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Content"></b-field>
      <div class="editor">
        <vue-editor v-model="post.content"></vue-editor>
        <hr />
        <div style="text-align: left">
          <span
            style="vertical-align: top; line-height: 33px; padding-right: 5px"
          >Youtube/External website</span><van-switch v-model="showYoutubeOptions" />
        </div>
        <b-field v-if="showYoutubeOptions" label="Youtube id(optional)">
          <b-input v-model="post.options.youtube_id"></b-input>
        </b-field>
        <b-field v-if="!showYoutubeOptions" label="Website(optional)">
          <b-input v-model="post.options.website"></b-input>
        </b-field>
        <youtube
          v-if="post.options.youtube_id"
          :video-id="post.options.youtube_id"
          player-height="300"
          :player-vars="{ autoplay: 1 }"
        ></youtube>
        <van-image
          v-if="post.options && post.options.youtube_id"
          :src="
            'https://img.youtube.com/vi/' + post.options.youtube_id + '/0.jpg'
          "
        ></van-image>
        <b-field label="Add some tags">
          <b-input
            v-model="post.tags"
            placeholder="Separate with comma"
          ></b-input>
        </b-field>
        <b-button type="is-info" @click="submit">Submit</b-button>
      </div>
    </section>
  </div>
</template>

<script>
// import MembersTemplate from '@/views/MembersTemplate.vue'
import ApiService from '@/services/ApiService'
import CatsSubcats from '@/constants/categories.json'
import { VueEditor } from 'vue2-editor'
export default {
  name: 'AdminNewPost',
  components: {
    VueEditor,
  },
  data() {
    return {
      showYoutubeOptions: true,
      post: {
        user_id: 1,
        content: null,
        options: {},
      },
      categories: CatsSubcats.categories,
      subcategories: [],
      editor: null,
    }
  },
  mounted() {
    this.post.content = '<p>Short description, Edit me</p>'
  },
  beforeDestroy() {},
  methods: {
    updateSubcategories(selectedCategory) {
      this.subcategories = CatsSubcats.subcategories[selectedCategory]
    },
    submit() {
      let post = Object.assign({}, this.post)
      ApiService.admin.createPost(post).then((resp) => {
        console.log(resp)
        this.$buefy.toast.open({
          message: 'Saved!',
          type: 'is-success',
        })
        this.$router.push({ name: 'admin.posts' })
      })
    },
  },
}
</script>
<style scoped>
.catClass {
  min-width: 300px;
}
</style>