<template>
  <div class="feed container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4 is-">Invites</h4>
    </div>
    <section>
      <vue-good-table
        :columns="columns"
        :rows="data"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'id', type: 'desc' },
        }"
        :search-options="{
          enabled: true,
        }"
      >
      </vue-good-table>
    </section>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
// import API from '../../services/dbConnection'
import ApiService from '@/services/ApiService'
export default {
  name: 'AdminInvites',
  components: {
    VueGoodTable
    // MembersTemplate
    // QrcodeVue
    // UUID
  },
  data() {
    // const mainUrl = 'http://londoneleganceclub.com/code/'
    return {
      data: [],
      backSide: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
          type: 'number',
        },
        {
          label: 'Code',
          field: 'code',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Used Date',
          field: 'used_date',
        },
        {
          label: 'Created date',
          field: 'created_date',
        },
        {
          label: 'Reference',
          field: 'reference',
        },
      ],
    }
  },
  methods: {
    toggleBackside() {
      this.backSide = !this.backSide
    },
    toggleTopMenu() {},
  },
  mounted() {
    ApiService.admin.getType('invitations').then((resp) => (this.data = resp.data))
  },
}
</script>
<style lang="scss" scoped>
.inviteBlock {
  border: 1px dashed #bcbcbc;
  border-bottom: 0px;
  border-left: 0px;
  position: relative;
  .backside {
    display: none;
  }
  &.hidden {
    padding: 1px;
    border: none !important;
    .top,
    .bottom,
    .logo,
    .qrcode,
    .qrlabel,
    .fa-recycle {
      opacity: 0;
    }
    .backside {
      display: block;
      position: absolute;
      top: 120px;
      font-weight: 200;
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
  }
  &:nth-child(odd) {
    border-left: 1px dashed #bcbcbc;
  }
  .top,
  .bottom {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .top {
    margin-bottom: 20px;
  }
  .qrlabel {
    font-size: 12px;
    font-weight: 200;
    display: block;
    margin-top: -2px;
    .fa-mobile-alt {
      padding-left: 3px;
    }
  }
  .logo {
    margin: 15px;
    margin-top: 30px;
    margin-left: 45px;
  }
  .qrcode {
    margin: 25px;
    margin-top: 5px;
    margin-right: 45px;
  }
  .fa-recycle {
    // margin-left: 20px;
    position: relative;
    bottom: 5px;
    left: 10px;
    display: inline;
  }
}
</style>
