export default {
  availableTags: {
    sizes: [
      '2',
      '2.5',
      '3',
      '3.5',
      '4',
      '4.5',
      '5',
      '5.5',
      '6',
      '6.5',
      '7',
      '7.5',
      '8',
      '8.5'
    ],
    types: [
      'Sandals',
      'Ankle Boots',
      'Knee-High Boots',
      'Over-Knee Boots',
      'Court Shoes',
      'Mid-Calf Boots',
      'Mules',
      'Slingbacks'
    ],
    colors: [
      'Black',
      'Brown',
      'Tan',
      'Grey',
      'Beige',
      'Burgundy',
      'Olive',
      'Nude',
      'White',
      'Purple',
      'Silver',
      'Gold',
      'Red',
      'Orange',
      'Green',
      'Blue',
      'Navy',
      'Pink',
      'Multi'
    ],
    heel: ['Stiletto', 'Wedge', 'Cone Heel', 'Block Heel', 'Cuban Heel'],
    upper: [
      'Leather',
      'Patent Leather',
      'Faux Patent Leather',
      'Suede',
      'Vegan Leather',
      'Faux Suede',
      'Satin',
      'Fabric'
    ],
    condition: ['New', 'Pre-Loved'],
    category: ['Designer', 'High-Street']
  },
  csvLabels: [
    {
      key: 'Handle',
      model: 'handle'
    },
    {
      key: 'Title',
      model: 'title'
    },
    {
      key: 'Body (HTML)',
      model: 'body'
    },
    {
      key: 'Vendor',
      model: 'vendor'
    },
    {
      key: 'Type',
      model: 'type'
    },
    {
      key: 'Tags',
      model: 'tags'
    },
    {
      key: 'Published',
      value: 'TRUE'
    },
    {
      key: 'Option1 Name',
      value: 'Title'
    },
    {
      key: 'Option1 Value',
      value: 'Default Title'
    },
    {
      key: 'Variant SKU',
      model: 'variant_sku'
    },
    {
      key: 'Variant Grams',
      value: 1000
    },
    {
      key: 'Variant Inventory Tracker',
      value: 'shopify'
    },
    {
      key: 'Variant Inventory Qty',
      value: 1
    },
    {
      key: 'Variant Fulfillment Service',
      value: 'manual'
    },
    {
      key: 'Variant Price',
      model: 'price'
    },
    {
      key: 'Variant Compare At Price',
      value: ''
    },
    {
      key: 'Variant Requires Shipping',
      value: 'TRUE'
    },
    {
      key: 'Variant Taxable',
      value: 'TRUE'
    },
    {
      key: 'Variant Barcode',
      value: ''
    },
    {
      key: 'Image Src',
      value: ''
    },
    {
      key: 'Image Position',
      value: 1
    },
    {
      key: 'Variant Weight Unit',
      value: 'kg'
    }
  ],
  designers: [
    'Jimmy Choo',
    'Manolo Blahnik',
    'Gucci',
    'Prada',
    'Hobbs',
    'Sergio Rossi',
    'Gianvito Rossi',
    'Ted Baker',
    'Tommy Hilfiger',
    'Whistles',
    'Stuart Weitzman',
    'Nicole Farhi',
    'L.K.Bennett',
    'Christian Louboutin',
    'Michael Kors',
    'Miu Miu',
    'Gina',
    'Gianmarco Lorenzi',
    'DKNY',
    'All saints',
    'Jaeger',
    'Karen Millen',
    'Kelsi Dagger',
    'Ralph Lauren',
    'Rupert Sanderson',
    'Reiss',
    'Russel Bromley',
    'Tory Burch',
    'Kurt Geiger',
    'Gino Rossi',
    'Pied a Terre',
    'Ivanka Trump',
    'Calvin Klein',
    'Ann Taylor',
    'Guess',
    'J Crew'
  ],
  nonDesigners: [
    'Aldo',
    'Topshop',
    'Zara',
    'Dune',
    'Geox',
    'Faith',
    'Nine West',
    'Duo',
    'Gabor',
    'Daniel',
    'Clarks',
    'Carvela',
    'Boden',
    'BCBG',
    'H&M',
    'Ecco',
    'Jones Bootmaker',
    'Jasper Conran',
    'John Lewis',
    'Manas',
    'Moda in Pelle',
    'Mint Velvet',
    'Next',
    'Office',
    'Sam Edelman',
    'Steve Madden',
    'Vince Camuto',
    'Unisa',
    'Asos',
    'Autograph',
    'M&S',
    'Massimo Dutti',
    'Miss Sixty'
  ],
  pricingInfo: {
    'Jimmy Choo': 'Courts: 50 - 120, Boots: 70 - 200',
    'Manolo Blahnik': 'Courts: 50 - 150, Boots: 70 - 200',
    Gucci: 'Courts: 50 - 180, Boots: 60 - 180',
    Prada: 'Courts: 40 - 80, Boots: 40 - 80',
    Hobbs: 'Courts: 15 - 40, Boots: 25 - 80',
    'Sergio Rossi': 'Courts: 25 - 80, Boots: 30 - 80',
    'Gianvito Rossi': 'Courts: 40 - 120, Boots: 50 - 150',
    'Ted Baker': 'Courts: 20 - 50, Boots: 30 - 50',
    'Tommy Hilfiger': 'Courts: 20 - 40, Boots: 40 - 70',
    Whistles: 'Courts: 15 - 30, Boots: 30 - 50',
    'Stuart Weitzman': 'Courts: 25 - 90, Boots: 30 - 120',
    'Nicole Farhi': 'Courts: 15 - 25, Boots: 30 - 80',
    'L.K.Bennett': 'Courts: 20 - 60, Boots: 25 - 80',
    'Christian Louboutin': 'Courts: 80 - 180, Boots: 80 - 200',
    'Michael Kors': 'Courts: 18 - 40, Boots: 30 - 60',
    'Miu Miu': 'Courts: 30 - 70, Boots: 40 - 70',
    Gina: 'Courts: 30 - 40, Boots: 30 - 50',
    'Gianmarco Lorenzi': 'Courts: 50 - 90, Boots: 50 - 120',
    DKNY: 'Courts: 20 - 40, Boots: 30 - 70',
    'All saints': 'Courts: 30 - 50, Boots: 30 - 80',
    Jaeger: 'Courts: 18 - 40, Boots: 25 - 60',
    'Karen Millen': 'Courts: 25 - 50, Boots: 30 - 80',
    'Kelsi Dagger': 'Courts: 20 - 40, Boots: 30 - 60',
    'Ralph Lauren': 'Courts: 20 - 40, Boots: 25 - 50',
    'Rupert Sanderson': 'Courts: 30 - 80, Boots: 30 - 120',
    Reiss: 'Courts: 20 - 40, Boots: 30 - 60',
    'Russel Bromley': 'Courts: 30 - 50, Boots: 30 - 90',
    'Tory Burch': 'Courts: 40 - 70, Boots: 40 - 90',
    'Kurt Geiger': 'Courts: 30 - 60, Boots: 30 - 70',
    'Gino Rossi': 'Courts: 20 - 40, Boots: 25 - 50',
    'Pied a Terre': 'Courts: 20 - 60, Boots: 30 - 60',
    'Ivanka Trump': 'Courts: 20 - 40, Boots: 30 - 50',
    'Calvin Klein': 'Courts: 20 - 40, Boots: 25 - 50',
    'Ann Taylor': 'Courts: 30 - 50, Boots: 30 - 50',
    Guess: 'Courts: 30 - 40, Boots: 30 - 60',
    'J Crew': 'Courts: 30 - 50, Boots: 30 - 60',
    Aldo: 'Courts: 20 - 40, Boots: 20 - 50',
    Topshop: 'Courts: 20 - 30, Boots: 20 - 40',
    Zara: 'Courts: 20 - 40, Boots: 30 - 50',
    Dune: 'Courts: 20 - 40, Boots: 30 - 60',
    Geox: 'Courts: 25 - 40, Boots: 30 - 60',
    Faith: 'Courts: 20 - 30, Boots: 20 - 50',
    'Nine West': 'Courts: 20 - 40, Boots: 20 - 50',
    Duo: 'Courts: 20 - 40, Boots: 20 - 50',
    Gabor: 'Courts: 20 - 40, Boots: 30 - 50',
    Daniel: 'Courts: 20 - 40, Boots: 20 - 50',
    Clarks: 'Courts: 20 - 30, Boots: 20 - 50',
    Carvela: 'Courts: 20 - 40, Boots: 20 - 50',
    Boden: 'Courts: 20 - 30, Boots: 30 - 50',
    BCBG: 'Courts: 20 - 40, Boots: 30 - 50',
    'H&M': 'Courts: 15 - 30, Boots: 20 - 40',
    Ecco: 'Courts: 20 - 40, Boots: 30 - 50',
    'Jones Bootmaker': 'Courts: 20 - 30, Boots: 25 - 50',
    'Jasper Conran': 'Courts: 15 - 40, Boots: 25 - 50',
    'John Lewis': 'Courts: 20 - 40, Boots: 20 - 40',
    Manas: 'Courts: 20 - 30, Boots: 30 - 50',
    'Moda in Pelle': 'Courts: 20 - 40, Boots: 30 - 60',
    'Mint Velvet': 'Courts: 20 - 40, Boots: 30 - 50',
    Next: 'Courts: 15 - 35, Boots: 20 - 50',
    Office: 'Courts: 15 - 40, Boots: 30 - 50',
    'Sam Edelman': 'Courts: 20 - 40, Boots: 30 - 60',
    'Steve Madden': 'Courts: 20 - 30, Boots: 20 - 40',
    'Vince Camuto': 'Courts: 20 - 40, Boots: 20 - 50',
    Unisa: 'Courts: 20 - 40, Boots: 30 - 50',
    Asos: 'Courts: 15 - 40, Boots: 20 - 40',
    Autograph: 'Courts: 20 - 40, Boots: 30 - 50',
    'M&S': 'Courts: 15 - 30, Boots: 20 - 40',
    'Miss Sixty': 'Courts: 20 - 40, Boots: 25 - 50',
    'Massimo Dutti': 'Courts: 20 - 40, Boots: 30 - 50'
  }
}
// Handle,Title,Body (HTML),Vendor,Type,Tags,Published,Option1 Name,Option1 Value,Option2 Name,Option2 Value,Option3 Name,Option3 Value,Variant SKU,Variant Grams,
// Variant Inventory Tracker,Variant Inventory Qty,Variant Inventory Policy,Variant Fulfillment Service,Variant Price,Variant Compare At Price,Variant Requires Shipping,Variant Taxable,Variant Barcode,Image Src,Image Position,Variant Weight Unit
