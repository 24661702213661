<template>
  <div>
    <div v-if="$route.path.includes('dashboard')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.dashboard'}">Dashboard</router-link>
      </div>
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.dashboard_stats'}">Stats</router-link>
      </div>
    </div>
    <div v-if="$route.path.includes('post')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.posts'}">Posts</router-link>
      </div>
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.edit_post'}">Add new Post</router-link>
      </div>
    </div>
    <div v-if="$route.path.includes('users')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.users'}">Users</router-link>
      </div>
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.users_new_user'}">Add new User</router-link>
      </div>
    </div>
    <div v-if="$route.path.includes('competitions')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.competitions'}">Competitions</router-link>
      </div>
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.competitions_new_competition'}">Start new Competition</router-link>
      </div>
    </div>
    <div v-if="$route.path.includes('comments')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.comments'}">Comments</router-link>
      </div>
      <div class="column is-paddingless">
        <!-- <router-link :to="{name: 'admin.comments_new_competition'}">Start new Competition</router-link> -->
      </div>
    </div>
    <div v-if="$route.path.includes('polls')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.polls'}">Polls</router-link>
      </div>
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.polls_new_poll'}">Start a new Poll</router-link>
      </div>
    </div>
    <div v-if="$route.path.includes('invites')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.invites'}">Invitations</router-link>
      </div>
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.invites_generate_new'}">Generate New</router-link>
      </div>
    </div>
    <div v-if="$route.path.includes('quotes')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.quotes'}">Quotes</router-link>
      </div>
      <div class="column is-paddingless">
        <!-- <router-link :to="{name: 'admin.quotes'}">Start a new Poll</router-link> -->
        <!-- <button @click="action">{{ data }}</button> -->
      </div>
    </div>
    <div v-if="$route.path.includes('other')" class="columns">
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.other'}">Other</router-link>
      </div>
      <div class="column is-paddingless">
        <router-link :to="{name: 'admin.shopify'}">Shopify</router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import MembersTemplate from '@/views/MembersTemplate.vue'

export default {
  name: 'AdminMain',
  components: {
    // MembersTemplate
  },
  props: [{
    data: String,
    action: Function
  }]
}
</script>

<style lang="scss" scoped>
$borderColor: #f7f7f7;
$submenuColor: #fffcf9;
$menuColor: #7ac5ff;
$subMenuColor: lighten($menuColor, 11%);
#submenu {
  background: $submenuColor;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  border-bottom: 1px solid rgb(28, 20, 20);
  overflow: hidden;
  box-shadow: rgb(242, 242, 242) 2px 2px 2px;
  &.hide {
    display: none;
  }
  .column {
    max-width: 300px;
  }
  a {
    padding-top: 7px;
    padding-bottom: 7px;
    display: inline-block;
    width: 100%;
    color: #000;
  }
  a.router-link-exact-active {
    // background: #fff1e0;
    background: $subMenuColor;

    color: #000;
  }
}
</style>