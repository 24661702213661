<template>
  <div class="feed container" style="background: #FFFFFF">
    <div class="hero">
      <h4 class="title is-4">Dashboard</h4>
    </div>
    <section>
      <div class="columns is-multiline">
        <div class="column">
          <div class="box notification is-primary">
            <div class="heading">Users</div>
            <div class="title">56</div>
            <div class="level">
              <div class="level-item">
                <div class>
                  <div class="heading">Today</div>
                  <div class="title is-5">25</div>
                </div>
              </div>
              <div class="level-item">
                <div class>
                  <div class="heading">1 Week</div>
                  <div class="title is-5">43</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box notification is-warning">
            <div class="heading">Posts</div>
            <div class="title">201</div>
            <div class="level">
              <div class="level-item">
                <div class>
                  <div class="heading">Today</div>
                  <div class="title is-5">30</div>
                </div>
              </div>
              <div class="level-item">
                <div class>
                  <div class="heading">1 Week</div>
                  <div class="title is-5">32</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box notification is-info">
            <div class="heading">Competitions</div>
            <div class="title">5</div>
            <div class="level">
              <div class="level-item">
                <div class>
                  <div class="heading">Today</div>
                  <div class="title is-5">1</div>
                </div>
              </div>
              <div class="level-item">
                <div class>
                  <div class="heading">1 Week</div>
                  <div class="title is-5">4</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box notification is-danger">
            <div class="heading">Comments</div>
            <div class="title">75</div>
            <div class="level">
              <div class="level-item">
                <div class>
                  <div class="heading">Today</div>
                  <div class="title is-5">4</div>
                </div>
              </div>
              <div class="level-item">
                <div class>
                  <div class="heading">1 WEEK</div>
                  <div class="title is-5">10</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="panel">
            <p class="panel-heading">Latest Comments</p>
            <div class="panel-block">
              <!-- <DataTable></DataTable> -->
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="panel">
            <p class="panel-heading">Latest Feedback</p>
            <div class="panel-block">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="panel">
            <p class="panel-heading">Latest Users</p>
            <div class="panel-block">
              
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="panel">
            <p class="panel-heading">Latest Posts</p>
            <div class="panel-block">
              <canvas id="chartScatter" width="400" height="400"></canvas>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import MembersTemplate from '@/views/MembersTemplate.vue'

export default {
  name: 'adminMain',
  components: {
    // MembersTemplate
  }
}
</script>
