<template>
  <div class="feed container" style="background: #FFFFFF">
    <div class="hero">
      <h4 class="title is-4 is-">Shopify Products CSV generator</h4>
    </div>
    <section style="overflow: scroll;">
      <table id="table" class="table" style="min-width: 100%; margin-bottom: 30px;">
        <thead>
          <tr>
            <!-- <th>Handle</th> -->
            <th>Title</th>
            <th>Type</th>
            <th>Body(HTML)</th>
            <th>Vendor</th>
            <th>Tags</th>
            <th>Price</th>
            <th>Img fldr</th>
            <th>Sku</th>
            <th>🤩</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in json_data" :key="item.id">
            <!-- <td>
              <input v-model="item.handle" disabled type="text" />
            </td>-->
            <td>
              <input v-model="item.title" type="text" style="width: 170px;" />
            </td>
            <td>
              <b-autocomplete
                v-model="item.type"
                :data="types"
                placeholder="e.g. Ankle Boots"
                style="width: 150px;"
                @focus="autocompleteFocus(index, 'type')"
                @select="option => selected = option"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
              <!-- <input v-model="item.type" type="text" /> -->
            </td>
            <td>
              <p class="htmlClick" @click="editField(index, 'body')">{{ item.body }}</p>
            </td>
            <td>
              <b-autocomplete
                v-model="item.vendor"
                :data="brands"
                placeholder="e.g. Gucci"
                style="width: 150px;"
                @focus="autocompleteFocus(index)"
                @select="option => selected = option"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
              <!-- <input v-model="item.vendor" type="text" /> -->
            </td>
            <td>
              <p class="htmlClick" @click="editTags(index, 'tags')">{{ item.tags }}</p>
              <!-- <textarea v-model="item.tags" style="width: 200px; height: 60px;"></textarea> -->
              <!-- <p class="htmlClick" @click="editField(index, 'tags')">{{ item.tags }}</p> -->
            </td>
            <td>
              <b-tooltip :label="brandTooltip">
                <input v-model="item.price" style="width: 50px;" type="text" />
              </b-tooltip>
            </td>
            <td>
              <input v-model="item.image_folder" style="width: 60px;" type="text" />
            </td>
            <td>
              <input v-model="item.variant_sku" style="width: 60px;" type="text" />
            </td>
            <td>
              <b-button type="is-primary" @click="duplicate(index)">Duplicate</b-button>
            </td>
          </tr>
        </tbody>
      </table>
      <b-button style="float: left;" type="is-primary" @click="addNewRow">Add New</b-button>
    </section>
    <section style="margin-top: 30px;">
      <button class="button is-primary is-medium" @click="save">Save</button>
      <button class="button is-default is-small" style="float: left;" @click="clearAll">Clear all</button>
    </section>
    <b-modal :active.sync="isTextModalActive" :on-cancel="saveTextarea">
      <p class style="background: white;">
        <vue-editor v-model="textContent"></vue-editor>
      </p>
    </b-modal>
    <b-modal :active.sync="isTagsModalActive" :on-cancel="saveTags">
      <div class="columns" style="background: white;">
        <div class="column">
          Size
          <br />
          <div v-for="size in availableTags.sizes" :key="size">
            <input
              :id="size"
              v-model="currentTags[size]"
              :name="size"
              :value="size"
              type="checkbox"
              @change="handleChange($event)"
            />
            <label :for="size" class="labelTag">{{ size }}</label>
            <br />
          </div>
        </div>
        <div class="column">
          Type
          <br />
          <div v-for="type in availableTags.types" :key="type">
            <input
              :id="type"
              v-model="currentTags[type]"
              :name="type"
              :value="type"
              type="checkbox"
              @change="handleChange($event)"
            />
            <label :for="type" class="labelTag small">{{ type }}</label>
            <br />
          </div>
        </div>
        <div class="column">
          Color
          <br />
          <div v-for="color in availableTags.colors" :key="color">
            <input
              :id="color"
              v-model="currentTags[color]"
              :name="color"
              :value="color"
              type="checkbox"
              @change="handleChange($event)"
            />
            <label :for="color" class="labelTag">{{ color }}</label>
            <br />
          </div>
        </div>
        <div class="column">
          Heel type
          <br />
          <div v-for="heel in availableTags.heel" :key="heel">
            <input
              :id="heel"
              v-model="currentTags[heel]"
              :name="heel"
              :value="heel"
              type="checkbox"
              @change="handleChange($event)"
            />
            <label :for="heel" class="labelTag small">{{ heel }}</label>
            <br />
          </div>
        </div>
        <div class="column">
          Upper
          <br />
          <div v-for="upper in availableTags.upper" :key="upper">
            <input
              :id="upper"
              v-model="currentTags[upper]"
              :name="upper"
              :value="upper"
              type="checkbox"
              @change="handleChange($event)"
            />
            <label :for="upper" class="labelTag small">{{ upper }}</label>
            <br />
          </div>
        </div>
        <div class="column">
          Condition
          <br />
          <div v-for="condition in availableTags.condition" :key="condition">
            <input
              :id="condition"
              v-model="currentTags[condition]"
              :name="condition"
              :value="condition"
              type="checkbox"
              @change="handleChange($event)"
            />
            <label :for="condition" class="labelTag small">{{ condition }}</label>
            <br />
          </div>
        </div>
        <div class="column">
          Designer/non
          <br />
          <div v-for="category in availableTags.category" :key="category">
            <input
              :id="category"
              v-model="currentTags[category]"
              :name="category"
              :value="category"
              type="checkbox"
              @change="handleChange($event)"
            />
            <label :for="category" class="labelTag small">{{ category }}</label>
            <br />
          </div>
        </div>
      </div>
      <p class style="background: white;">
        <textarea v-model="tagsContent" class="tagsArea" style="width: 100%; padding: 10px;"></textarea>
      </p>
    </b-modal>

    <!-- <button @click="prepareData">Prepare data</button> -->
    <download-csv v-if="final_data.length > 0" style="float:right;" :data="final_data">
      Download CSV
      <b-icon pack="fas" icon="download" size="is-small"></b-icon>
    </download-csv>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
// import API from '../../services/dbConnection'
// import QrcodeVue from 'qrcode.vue'
// import MembersTemplate from '@/views/MembersTemplate.vue'
import JsonCSV from 'vue-json-csv'
import { VueEditor } from 'vue2-editor'
import Logic from './logic/shopify'
import Slugify from 'slugify'

export default {
  name: 'AdminInvites',
  components: {
    'download-csv': JsonCSV,
    VueEditor
    // MembersTemplate
    // QrcodeVue
    // UUID
  },
  data() {
    return {
      isTextModalActive: false,
      isTagsModalActive: false,
      textContent: '',
      tagsContent: '',
      folderBaseUrl: 'https://lec2020.s3.eu-west-2.amazonaws.com/',
      selectedTextModel: {
        key: '',
        index: null
      },
      selectedTagsModel: {
        key: '',
        index: null
      },
      selectedBrandIndex: 0,
      selectedTypeIndex: 0,
      final_data: [],
      json_data: [
        {
          id: 1,
          handle: '',
          title: '',
          body: '',
          vendor: '',
          type: '',
          tags: '',
          price: '',
          image_folder: '',
          variant_sku: ''
        }
      ],
      availableTags: Logic.availableTags,
      currentTags: {},
      csvLabels: Logic.csvLabels
    }
  },
  computed: {
    brands() {
      // return [];
      const combined = [].concat(Logic.designers, Logic.nonDesigners)
      return combined.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(
              this.json_data[this.selectedBrandIndex].vendor.toLowerCase()
            ) >= 0
        )
      })
    },
    types() {
      return Logic.availableTags.types.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(
              this.json_data[this.selectedTypeIndex].type.toLowerCase()
            ) >= 0
        )
      })
    },
    brandTooltip() {
      const vendor = this.json_data[this.selectedBrandIndex].vendor;
      return vendor + ' ' + Logic.pricingInfo[vendor];
      // return Logic.designers.filter(option => {
      //   return (
      //     option
      //       .toString()
      //       .toLowerCase()
      //       .indexOf(
      //         this.json_data[this.selectedBrandIndex].vendor.toLowerCase()
      //       ) >= 0
      //   )
      // })
    }
  },
  mounted() {
    if (localStorage.getItem('items')) {
      try {
        this.json_data = JSON.parse(localStorage.getItem('items'))
      } catch (e) {
        alert(e)
        localStorage.removeItem('items')
      }
    }
  },
  methods: {
    addNewRow() {
      this.json_data.push({
        id: this.json_data.length + 1,
        handle: '',
        title: '',
        body: '',
        vendor: '',
        type: '',
        tags: '',
        price: '',
        image_folder: ''
      })
    },
    test(value, something) {
      alert(value + something.value)
    },
    saveTextarea() {
      this.json_data[this.selectedTextModel.index][
        this.selectedTextModel.key
      ] = this.textContent
    },
    saveTags() {
      this.json_data[this.selectedTagsModel.index][
        this.selectedTagsModel.key
      ] = this.tagsContent
    },
    editField(index, key) {
      const currentRow = this.json_data[index]
      this.textContent = currentRow[key]
      if (this.textContent == '') {
        if (
          ['Knee-High Boots', 'Mid-Calf Boots', 'Over-Knee Boots'].indexOf(
            currentRow.type
          ) > -1
        ) {
          this.textContent =
            '<h2>Title</h2><h5>Size: </h5><h5>Heel Height: </h5><h5>Platform: </h5><h5>Ankle measurement (flat): </h5><h5>Calf measurement (flat): </h5><h5>Upper: </h5><h5>Condition: </h5>'
        } else if (['Ankle Boots'].indexOf(currentRow.type) > -1) {
          this.textContent =
            '<h2>Title</h2><h5>Size: </h5><h5>Heel Height: </h5><h5>Platform: </h5><h5>Ankle measurement (flat): </h5><h5>Upper: </h5><h5>Condition: </h5>'
        } else if (
          ['Court Shoes', 'Sandals', 'Mules', 'Slingbacks'].indexOf(
            currentRow.type
          ) > -1
        ) {
          this.textContent =
            '<h2>Title</h2><h5>Size: </h5><h5>Heel Height: </h5><h5>Platform: </h5><h5>Ankle measurement (flat): </h5><h5>Upper: </h5><h5>Condition: </h5>'
        }
      }
      this.selectedTextModel = {
        key,
        index
      }
      this.isTextModalActive = true
    },
    editTags(index, key) {
      this.tagsContent = this.json_data[index][key]
      this.selectedTagsModel = {
        key,
        index
      }
      this.currentTags = {};
      const currentRow = this.json_data[index]
      if (this.json_data[index]['tags']) {
        const split = this.json_data[index]['tags'].split(',')
        for (let i = 0; i < split.length; i++) {
          this.currentTags[split[i]] = true
        }
      }
      if (Logic.designers.indexOf(currentRow.vendor) > -1) {
        this.currentTags['Designer'] = true
        this.currentTags['High-Street'] = false
        if (!this.tagsContent.includes(',Designer')) {
          this.tagsContent += ',Designer'
        }
      } else {
        this.currentTags['High-Street'] = true
        this.currentTags['Designer'] = false
        if (!this.tagsContent.includes(',High-Street')) {
          this.tagsContent += ',High-Street'
        }
      }
      this.isTagsModalActive = true
    },
    duplicate(index) {
      let newItem = this.json_data[index]
      newItem.id = this.json_data.length + 1
      newItem = JSON.parse(JSON.stringify(newItem))
      this.json_data.push(newItem)
    },
    prepareData() {
      const currentData = JSON.parse(JSON.stringify(this.json_data))
      // const
      var finalData = []

      var currentRow
      var currentLabel
      var newObj = {}
      for (let m = 0; m < currentData.length; m++) {
        currentRow = currentData[m]
        newObj = {}

        for (let i = 0; i < this.csvLabels.length; i++) {
          currentLabel = this.csvLabels[i]
          if (currentLabel.key === 'Image Src') {
            newObj['Image Src'] =
              this.folderBaseUrl + currentRow['image_folder'] + '/ (01).jpg'
          } else if (currentLabel.key === 'Handle') {
            newObj['Handle'] = Slugify(currentRow['title'])
          } else if (currentLabel.value) {
            newObj[currentLabel.key] = currentLabel.value
          } else if (currentLabel.model) {
            newObj[currentLabel.key] = currentRow[currentLabel.model]
          }
        }
        finalData.push(newObj)
        // add 4 other images
        finalData.push(
          this.imageRowObj(newObj['Handle'], currentRow['image_folder'], 2)
        )
        finalData.push(
          this.imageRowObj(newObj['Handle'], currentRow['image_folder'], 3)
        )
        finalData.push(
          this.imageRowObj(newObj['Handle'], currentRow['image_folder'], 4)
        )
        finalData.push(
          this.imageRowObj(newObj['Handle'], currentRow['image_folder'], 5)
        )
      }
      this.final_data = finalData
      console.log(finalData)
    },
    imageRowObj(handle, folder, image_position) {
      let returnObj = {}
      for (let i = 0; i < this.csvLabels.length; i++) {
        if (this.csvLabels[i].key === 'Handle') {
          returnObj[this.csvLabels[i].key] = handle
        } else if (this.csvLabels[i].key === 'Image Src') {
          returnObj[this.csvLabels[i].key] =
            this.folderBaseUrl + folder + '/ (0' + image_position + ').jpg'
        } else if (this.csvLabels[i].key === 'Image Position') {
          returnObj[this.csvLabels[i].key] = image_position
        } else {
          returnObj[this.csvLabels[i].key] = ''
        }
      }
      return returnObj
    },
    handleChange: function(e) {
      // const name = e.target.name
      console.log(e.target)
      if (this.currentTags[e.target.value]) {
        this.tagsContent += ',' + e.target.value
      } else {
        this.tagsContent = this.tagsContent.replace(',' + e.target.value, '')
      }
      // if (this.layer[name]) {
      //   Object.keys(this.layer).map(key => {
      //     if (key != name) {
      //       this.layer[key] = false
      //     }
      //   })
      // }
    },
    save() {
      const parsed = JSON.stringify(this.json_data)
      localStorage.setItem('items', parsed)
      // alert('Saved')
      this.prepareData()
    },
    clearAll() {
      if (confirm('Are you sure you want to clear everything ?')) {
        this.json_data = []
        this.save()
      }
    },
    autocompleteFocus(index, field) {
      if (field === 'type') {
        this.selectedTypeIndex = index
      } else {
        this.selectedBrandIndex = index
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#table {
  input {
    min-height: 30px;
  }
}
.labelTag {
  width: 60px;
  text-align: left;
  padding-left: 8px;
  display: inline-block;
  &.small {
    font-size: 12px;
    width: 100px;
    text-align: left;
    padding-left: 5px;
  }
}
.tagsArea {
  font-size: 15px;
}
.htmlClick {
  width: 180px;
  min-height: 30px;
  background: hsl(0, 0%, 98%);
  font-size: 11px;
}
.inviteBlock {
  border: 1px dashed #bcbcbc;
  border-bottom: 0px;
  border-left: 0px;
  position: relative;
  .backside {
    display: none;
  }
  &.hidden {
    padding: 1px;
    border: none !important;
    .top,
    .bottom,
    .logo,
    .qrcode,
    .qrlabel,
    .fa-recycle {
      opacity: 0;
    }
    .backside {
      display: block;
      position: absolute;
      top: 120px;
      font-weight: 200;
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
  }
  &:nth-child(odd) {
    border-left: 1px dashed #bcbcbc;
  }
  .top,
  .bottom {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .top {
    margin-bottom: 20px;
  }
  .qrlabel {
    font-size: 12px;
    font-weight: 200;
    display: block;
    margin-top: -2px;
    .fa-mobile-alt {
      padding-left: 3px;
    }
  }
  .logo {
    margin: 15px;
    margin-top: 30px;
    margin-left: 45px;
  }
  .qrcode {
    margin: 25px;
    margin-top: 5px;
    margin-right: 45px;
  }
  .fa-recycle {
    // margin-left: 20px;
    position: relative;
    bottom: 5px;
    left: 10px;
    display: inline;
  }
}
</style>