<template>
  <div class="feed container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">Posts</h4>
    </div>
    <section>
      <vue-good-table
        :columns="columns"
        :rows="posts"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'id', type: 'desc' },
        }"
        :search-options="{
          enabled: true,
        }"
        @on-row-click="onRowClick"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'options' && props.row.options">
            <span v-if="props.row.options.youtube_id">
              <span>Youtube: </span><a target="_blank" :href="props.row.options.youtube_id">Link</a>
              <van-image
                :src="
                  'https://img.youtube.com/vi/' +
                    props.row.options.youtube_id +
                    '/0.jpg'
                "
                :lazy-load="true"
              />
            </span>
            <span v-if="props.row.options.website">
              <span>Website: </span><a target="_blank" :href="props.row.options.website">Link</a>
              <van-image :src="props.row.options.image" :lazy-load="true" /></span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </section>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'AdminMain',
  components: {
    VueGoodTable,
    // MembersTemplate
  },
  data() {
    return {
      columns: [
        {
          label: 'Id',
          field: 'id',
          type: 'number',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Sub-Category',
          field: 'sub_category',
        },
        {
          label: 'Likes',
          field: 'likes',
          type: 'number',
        },
        {
          label: 'Options',
          field: 'options',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Created',
          field: 'created_date',
        },
      ],
      posts: [],
    }
  },
  async mounted() {
    const result = await ApiService.admin.getType('posts')
    this.posts = result.data
  },
  methods: {
    onRowClick(params) {
      console.log(params)
    },
  },
}
</script>
