<template>
    <div class="feed container" style="background: #FFFFFF">
      <div class="hero"><h4 class="title is-4">Polls</h4></div>
      <section>
        <p>Beauty</p>
      </section>
    </div>
</template>

<script>

// import MembersTemplate from '@/views/MembersTemplate.vue'

export default {
  name: 'adminMain',
  components: {
    // MembersTemplate
  }
}
</script>
