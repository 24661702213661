<template>
  <div class="feed container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4 is-">Comments</h4>
    </div>
    <section>
      <vue-good-table
        :columns="columns"
        :rows="data"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'id', type: 'desc' },
        }"
        :search-options="{
          enabled: true,
        }"
      >
      </vue-good-table>
    </section>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import ApiService from '@/services/ApiService'
export default {
  name: 'AdminComments',
  components: {
    VueGoodTable
    // MembersTemplate
    // QrcodeVue
    // UUID
  },
  data() {
    // const mainUrl = 'http://londoneleganceclub.com/code/'
    return {
      data: [],
      backSide: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
          type: 'number',
        },
        {
          label: 'user_id',
          field: 'user_id',
        },
        {
          label: 'post_id',
          field: 'post_id',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Comment',
          field: 'comment',
        },
        {
          label: 'Unix',
          field: 'unix_timestamp',
        },
        {
          label: 'Created date',
          field: 'created_date',
        },
      ],
    }
  },
  methods: {
    toggleBackside() {
      this.backSide = !this.backSide
    },
    toggleTopMenu() {},
  },
  mounted() {
    ApiService.admin.getType('post_comments').then((resp) => (this.data = resp.data))
  },
}
</script>
