var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feed container",staticStyle:{"background":"#ffffff"}},[_vm._m(0),_c('section',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.posts,"sort-options":{
        enabled: true,
        initialSortBy: { field: 'id', type: 'desc' },
      },"search-options":{
        enabled: true,
      }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'options' && props.row.options)?_c('span',[(props.row.options.youtube_id)?_c('span',[_c('span',[_vm._v("Youtube: ")]),_c('a',{attrs:{"target":"_blank","href":props.row.options.youtube_id}},[_vm._v("Link")]),_c('van-image',{attrs:{"src":'https://img.youtube.com/vi/' +
                  props.row.options.youtube_id +
                  '/0.jpg',"lazy-load":true}})],1):_vm._e(),(props.row.options.website)?_c('span',[_c('span',[_vm._v("Website: ")]),_c('a',{attrs:{"target":"_blank","href":props.row.options.website}},[_vm._v("Link")]),_c('van-image',{attrs:{"src":props.row.options.image,"lazy-load":true}})],1):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero"},[_c('h4',{staticClass:"title is-4"},[_vm._v("Posts")])])}]

export { render, staticRenderFns }