<template>
  <div id="app">
    <section style="overflow: hidden">
      <button v-if="hideMenu" @click="hideMenu = false" style="height: 5px;background: #CCC; border: 0;"></button>
      <div
        id="nav"
        class="columns is-mobile menuDiv"
        :class="{ hide: hideMenu }"
      >
        <div class="column">
          <router-link
            to="/admin/dashboard"
            :class="{
              'router-link-exact-active': $route.path.includes('dashboard'),
            }"
          >
            <b-icon pack="fas" icon="home" size="is-small"></b-icon>
            <br />
            <small>Home</small>
          </router-link>
        </div>
        <div class="column">
          <router-link
            to="/admin/posts"
            :class="{
              'router-link-exact-active': $route.path.includes('posts'),
            }"
          >
            <b-icon pack="fas" icon="table" size="is-small"></b-icon>
            <br />
            <small>Posts</small>
          </router-link>
        </div>
        <div class="column">
          <router-link
            to="/admin/users"
            :class="{
              'router-link-exact-active': $route.path.includes('users'),
            }"
          >
            <b-icon pack="fas" icon="users" size="is-small"></b-icon>
            <br />
            <small>Users</small>
          </router-link>
        </div>
        <div class="column">
          <router-link
            to="/admin/competitions"
            :class="{
              'router-link-exact-active': $route.path.includes('competitions'),
            }"
          >
            <b-icon pack="fas" icon="images" size="is-small"></b-icon>
            <br />
            <small>Competitions</small>
          </router-link>
        </div>
        <div class="column">
          <router-link
            to="/admin/comments"
            :class="{
              'router-link-exact-active': $route.path.includes('comments'),
            }"
          >
            <b-icon pack="fas" icon="comments" size="is-small"></b-icon>
            <br />
            <small>Comments</small>
          </router-link>
        </div>
        <div class="column">
          <router-link
            to="/admin/polls"
            :class="{
              'router-link-exact-active': $route.path.includes('polls'),
            }"
          >
            <b-icon pack="fas" icon="poll" size="is-small"></b-icon>
            <br />
            <small>Polls</small>
          </router-link>
        </div>
        <div class="column">
          <router-link
            to="/admin/invites"
            :class="{
              'router-link-exact-active': $route.path.includes('invites'),
            }"
          >
            <b-icon pack="fas" icon="mail-bulk" size="is-small"></b-icon>
            <br />
            <small>Invites</small>
          </router-link>
        </div>
        <div class="column">
          <router-link
            to="/admin/quotes"
            :class="{
              'router-link-exact-active': $route.path.includes('quotes'),
            }"
          >
            <b-icon pack="fas" icon="quote-right" size="is-small"></b-icon>
            <br />
            <small>Quotes</small>
          </router-link>
        </div>
        <div class="column">
          <router-link
            to="/admin/other"
            :class="{
              'router-link-exact-active': $route.path.includes('other'),
            }"
          >
            <b-icon pack="fas" icon="crown" size="is-small"></b-icon>
            <br />
            <small>Other</small>
          </router-link>
        </div>
        <div class="column">
          <router-link to="/members/main">
            <b-icon
              pack="fas"
              icon="arrow-alt-circle-right"
              size="is-small"
            ></b-icon>
            <br />
            <small>Back to User section</small>
          </router-link>
        </div>
        <div class="column">
          <div @click="actionHideMenu()">
            <b-icon pack="fas" icon="window-minimize" size="is-small"></b-icon>
            <br />
            <small>Hide menu</small>
          </div>
        </div>
      </div>
    </section>
    <div id="submenu" :class="{ hide: hideMenu }">
      <AdminSubMenu data="kasalotas" :action="testFunc"></AdminSubMenu>
    </div>
    <transition name="zoom-in" mode="out-in">
      <router-view />
    </transition>
    <back-to-top visibleoffset="200">
      <i class="fas fa-chevron-up"></i>
    </back-to-top>
    <!-- <footer class="footer" style="padding: 5px">
      <div class="content has-text-centered">
        <small style="font-size: 10px"
          >&copy; Copyright 2021 London Elegance Club. All rights
          reserved.</small
        >
      </div>
    </footer> -->
  </div>
</template>

<script>
// @ is an alias to /src
import AdminSubMenu from '@/components/admin/AdminSubMenu.vue'

export default {
  name: 'AdminTemplate',
  components: {
    AdminSubMenu,
  },
  data() {
    return {
      isImageModalActive: false,
      isCardModalActive: false,
      hideMenu: false,
    }
  },
  beforeMount() {
    if (!this.$store.state.general.token) {
      this.$notify({ type: 'warning', message: 'You need to sign in first.' })
      this.$router.push('/')
    }
  },
  methods: {
    actionHideMenu() {
      this.hideMenu = true
    },
    testFunc() {
      alert('la test')
    },
  },
}
</script>

<style lang="scss">
// Import Bulma's core
@import '~bulma/sass/utilities/_all';

// Set your colors
$primary: #ffa549;
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);

$warning: #ffd42a;
$danger: #f33b60;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black,
  ),
  'black': (
    $black,
    $white,
  ),
  'light': (
    $light,
    $light-invert,
  ),
  'dark': (
    $dark,
    $dark-invert,
  ),
  'primary': (
    $primary,
    $primary-invert,
  ),
  'info': (
    $info,
    $info-invert,
  ),
  'success': (
    $success,
    $success-invert,
  ),
  'warning': (
    $warning,
    $warning-invert,
  ),
  'danger': (
    $danger,
    $danger-invert,
  ),
  'twitter': (
    $twitter,
    $twitter-invert,
  ),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles

@import '~bulma';
@import 'buefy/src/scss/components/_autocomplete.scss';
@import 'buefy/src/scss/components/_tooltip.scss';
</style>

<style lang="scss" scoped>
$borderColor: #f7f7f7;
$submenuColor: #fffcf9;
$menuColor: #7ac5ff;
$subMenuColor: lighten($menuColor, 11%);
#nav {
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 4px;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  background: #fdfdfd;
  &.hide {
    display: none;
  }
  a {
    display: inline-block;
    // min-width: 100px;
  }
  a.router-link-exact-active {
    color: $menuColor;
  }
}
#submenu {
  background: $submenuColor;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  border-bottom: 1px solid rgb(230, 230, 230);
  overflow: hidden;
  box-shadow: rgb(242, 242, 242) 2px 2px 2px;
  &.hide {
    display: none;
  }
  a {
    padding-top: 7px;
    padding-bottom: 7px;
    display: inline-block;
    width: 100%;
    color: #616161;
  }
  a.router-link-exact-active {
    // background: #fff1e0;
    background: $subMenuColor;
    color: #616161;
  }
}
hr {
  // padding: 0;
  // margin: 0;
  background-color: #dadada;
  height: 1px;
}
.menuDiv small {
  font-size: 9px;
  display: inline-block;
  line-height: 11px;
}
.noStyleButton {
  border: 0;
  padding: 3px;
  background: none;
}
.fullwidth {
  display: block;
  width: 100%;
}
#menuModal {
  .card {
    border: 3px solid #bbb;
    border-radius: 10px;
    background: url('https://t4.ftcdn.net/jpg/01/38/00/91/500_F_138009187_zmz2cSm3qoL2fwmIjKMV2JD8z9e3z4Rp.jpg');
    // background: rgb(255,255,255);
    // background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(227,227,227,1) 51%, rgba(235,235,235,1) 100%);
    padding: 1.5rem;
    padding-top: 0;
    padding-bottom: 20px;
  }
  button {
    margin-bottom: 2px;
    font-family: 'Roboto', sans-serif;
    height: 2.55em;
    opacity: 90%;
  }
  h2 {
    font-weight: 700;
  }
}
.footer {
  background: $submenuColor;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
}
</style>
