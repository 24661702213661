var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('section',{staticStyle:{"overflow":"hidden"}},[(_vm.hideMenu)?_c('button',{staticStyle:{"height":"5px","background":"#CCC","border":"0"},on:{"click":function($event){_vm.hideMenu = false}}}):_vm._e(),_c('div',{staticClass:"columns is-mobile menuDiv",class:{ hide: _vm.hideMenu },attrs:{"id":"nav"}},[_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('dashboard'),
          },attrs:{"to":"/admin/dashboard"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"home","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Home")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('posts'),
          },attrs:{"to":"/admin/posts"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"table","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Posts")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('users'),
          },attrs:{"to":"/admin/users"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"users","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Users")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('competitions'),
          },attrs:{"to":"/admin/competitions"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"images","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Competitions")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('comments'),
          },attrs:{"to":"/admin/comments"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"comments","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Comments")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('polls'),
          },attrs:{"to":"/admin/polls"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"poll","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Polls")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('invites'),
          },attrs:{"to":"/admin/invites"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"mail-bulk","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Invites")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('quotes'),
          },attrs:{"to":"/admin/quotes"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"quote-right","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Quotes")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
            'router-link-exact-active': _vm.$route.path.includes('other'),
          },attrs:{"to":"/admin/other"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"crown","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Other")])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{attrs:{"to":"/members/main"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"arrow-alt-circle-right","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Back to User section")])],1)],1),_c('div',{staticClass:"column"},[_c('div',{on:{"click":function($event){return _vm.actionHideMenu()}}},[_c('b-icon',{attrs:{"pack":"fas","icon":"window-minimize","size":"is-small"}}),_c('br'),_c('small',[_vm._v("Hide menu")])],1)])])]),_c('div',{class:{ hide: _vm.hideMenu },attrs:{"id":"submenu"}},[_c('AdminSubMenu',{attrs:{"data":"kasalotas","action":_vm.testFunc}})],1),_c('transition',{attrs:{"name":"zoom-in","mode":"out-in"}},[_c('router-view')],1),_c('back-to-top',{attrs:{"visibleoffset":"200"}},[_c('i',{staticClass:"fas fa-chevron-up"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }