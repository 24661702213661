<template>
  <div class="feed container" style="background: #FFFFFF">
    <div class="hero">
      <h4 class="title is-4 is-">
        Other
      </h4>
    </div>
    <section>
      <button><router-link :to="{name: 'admin.shopify'}">Shopify</router-link></button>
    </section>
  </div>
</template>

<script>
// import API from '../../services/dbConnection'
// import QrcodeVue from 'qrcode.vue'
// import MembersTemplate from '@/views/MembersTemplate.vue'
// import JsonCSV from 'vue-json-csv';

export default {
  name: 'AdminInvites',
  components: {
    // MembersTemplate
    // QrcodeVue
    // UUID
  },
  data() {
    return {
      json_data: [{
        id:5
      }]
    }
  },
  methods: {
    addNewRow() {
      this.backSide = !this.backSide;
    },
  }
}
</script>
<style lang="scss" scoped>
.inviteBlock {
  border: 1px dashed #bcbcbc;
  border-bottom: 0px;
  border-left: 0px;
  position: relative;
  .backside{
    display: none;
  }
  &.hidden{
    padding: 1px;
    border: none !important;
    .top, .bottom, .logo, .qrcode, .qrlabel, .fa-recycle{
      opacity: 0;
    }
    .backside{
      display: block;
      position: absolute;
      top: 120px;
      font-weight: 200; 
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
  }
  &:nth-child(odd){
    border-left: 1px dashed #bcbcbc;
  }
  .top, .bottom {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .top{
    margin-bottom: 20px;
  }
  .qrlabel{
    font-size: 12px;
    font-weight: 200;
    display: block;
    margin-top: -2px;
    .fa-mobile-alt{
      padding-left: 3px;
    }
  }
  .logo {
    margin: 15px;
    margin-top: 30px;
    margin-left: 45px;
  }
  .qrcode {
    margin: 25px;
    margin-top: 5px;
    margin-right: 45px;
  }
  .fa-recycle{
    // margin-left: 20px;
    position: relative; bottom: 5px; left: 10px;
    display: inline;
  }
}
</style>
